// reducers/newUserReducer.js

const initialState = {}

const newUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEW_USER':
      return {
        ...state,
        ...action.payload // Assuming action.payload contains the new user data
      }

    case 'REMOVE_NEW_USER':
      return initialState

    default:
      return state
  }
}

export default newUserReducer
