// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  allData: []
}

const appVersion = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_APP_VERSION_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    // case 'GET_USER':
    //   return { ...state, selectedUser: action.selectedUser }
    // case 'ADD_USER':
    //   return { ...state }
    case 'DELETE_APP_VERSION':
      return { ...state }
    default:
      return { ...state }
  }
}
export default appVersion
